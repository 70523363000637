import json from '@/static/listTest.json'
const tableMixin = {
  data() {
    return {
      tableHeight: 'calc(100vh - 300px)',
      paginationName: 'tablePagination',
      tableBackground: true,
      tableLoading: false,
      tableTotalSize: 0,
      tablePageSize: 20,
      pagerCount: 5,
      tableData: [],
      currentPage: 1,
      tableListJson: json, // 测试表格字段管理数据
      tableRef: 'testTable',
      printmaxNumTable: 5, //  最大打印条数
      headerCStyle: { background: 'rgb(247,248,252)' },
      isProductCjq: process.env.NODE_ENV !== 'production', // 是否是开发环境
      searchKeys: [], // 用于高级搜索的关键词属性
      chioceSetIndex: null, // 是否显示操作浮层
      isShowDomAnimate: false, // 浮层动画
      rowIdxMouse: null, // 滑入行的唯一索引
      isOutMouse: true, // 鼠标是否在外面
      isScorllWidth: 0 // 滚动条横向滚动距离

    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.doLayout()
      }
    })
  },
  mounted() {
    console.log('tableList', this.tableListJson)
    this.$nextTick(() => {
      this.handleCurrentChange(1)
      // this.tablePageSize = Math.floor(this.$minCommon.SetTableHeight(330) / 29)
    })
  },
  methods: {
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      return null
    },
    async searchGetTableDataAsync() {
      this.tableLoading = true
      //   this.catchAsync(
      // { Message, Total }
      const { Message, Total } = await this.getTableDataAsync(1, this.tablePageSize)
      this.tableLoading = false
      // if (RetCode === '0' && Message.length > 0) {
      this.tableTotalSize = Total
      console.log('Message', Message)
      this.tableData = Message
      // }
    },
    async refreshCurrentChange() {
      await this.handleCurrentChange(this.$refs[this.paginationName].internalCurrentPage, this.getTableDataAsync)
    },
    async handleCurrentChange(page) {
      this.tableLoading = true
      const { Message, Total, RetCode, FieldStr } = await this.getTableDataAsync(page || 1, this.tablePageSize)
      this.tableLoading = false
      // $tableListName
      if (RetCode === '0' && Message.length > 0) {
        this.tableTotalSize = Total
        this.tableData = Message
      }
      if (RetCode === '0' && FieldStr) {
        if (JSON.parse(FieldStr).length > 0 && this.$refs.FieldManagement) {
          this.$nextTick(() => {
            this.tableListJson[ this.$refs.FieldManagement.name ] = JSON.parse(FieldStr)
          })
        }
      }
    },
    changeListTable(e, name, ActionName, type) {
      this.tableListJson[name] = e
      const arr = this.tableListJson[name].filter(item => !item.isShow)
      const type_ = type === 0 ? '' : type
      this.$api.SetUserField({ ActionName: ActionName + type_, FieldStr: JSON.stringify(arr) }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('字段管理设置错误：' + res.RetMsg)
      }).catch(err => {
        return this.$message.error('字段管理设置接口错误：' + err)
      })
    },
    // 处理所需展示的关键词
    setKeysData(formSearch, adSearchForm) {
      this.searchKeys = []
      for (let i = 0; i < adSearchForm.length; i++) {
        if (adSearchForm[i].check) {
          return
        }
        // 如果是日期
        if (adSearchForm[i].date) {
          if (formSearch[adSearchForm[i].value].length > 0) {
            this.searchKeys.push({ name: adSearchForm[i].name, value: formSearch[adSearchForm[i].value], prop: adSearchForm[i].value })
          }
        }
        if (!adSearchForm[i].date) {
          if (formSearch[adSearchForm[i].value]) {
            this.searchKeys.push({ name: adSearchForm[i].name, value: formSearch[adSearchForm[i].value], prop: adSearchForm[i].value })
          }
        }
      }
      console.log('请看', this.searchKeys)
    },
    // 清除某个筛选条件
    closeTag(tag, formSearch, adSearchForm, DateArr, start, end, start2, end2) {
      if (tag.prop === DateArr) {
        if (start && end) {
          formSearch[start] = ''
          formSearch[end] = ''
        }
        if (start2 && end2) {
          formSearch[start2] = ''
          formSearch[end2] = ''
        }
      }
      if (Array.isArray(DateArr)) {
        if (tag.prop === DateArr[0]) {
          if (start && end) {
            formSearch[start] = ''
            formSearch[end] = ''
          }
        }
        if (tag.prop === DateArr[1]) {
          if (start2 && end2) {
            formSearch[start2] = ''
            formSearch[end2] = ''
          }
        }
      }

      if (formSearch[tag.prop]) {
        formSearch[tag.prop] = Array.isArray(tag.value) ? [] : ''
      }
      this.setKeysData(formSearch, adSearchForm)
      this.handleCurrentChange()
    },
    // 隐藏浮层
    hideSetDom() {
      this.$nextTick(() => {
        this.isShowDomAnimate = !this.isShowDomAnimate
        const dom = document.getElementsByClassName('hoverDom')[0]
        const hei_ = document.getElementsByClassName('el-table__row')[0].offsetHeight
        if (this.isShowDomAnimate) {
          setTimeout(() => {
            return dom.style.display = 'none'
          }, 1000)
          return
        }
        setTimeout(() => {
          return dom.style.display = 'block'
        }, 1000)
        dom.style.display = 'block'
        const btn_hei = document.getElementsByClassName('OperationButton')[0].offsetHeight
        return dom.style.top = Math.floor((hei_ - btn_hei) / 2) + 'px'
      })
    },
    // 鼠标划入行
    mouseEnter(row, IDX, xw, dw, multipleTableC, arr, isUse) {
      /* row 每一行的数据
       *  xw 操作按钮向左偏移量
       * dw 切换按钮向左偏移量
       *  multipleTableC 表格的ref
       *  arr 表格数据
       *  isUse 使用的条件 为了有多个条件的表格设置
       * IDX 唯一索引
      */
      if (this.formSearch.SelectType !== isUse) return
      // console.log('row', row)
      this.rowIdxMouse = row[IDX]
      this.isOutMouse = false
      this.chioceSetIndex = arr.findIndex(item => item[IDX] === row[IDX])
      this.$nextTick(() => {
        const bodyWidth = multipleTableC.bodyWidth.split('px')[0]
        const dom = document.getElementsByClassName('hoverDom')[0]
        const left_ = document.getElementsByClassName('el-table')[0].scrollWidth
        const clickBg = document.getElementById('clickBg')
        const a = bodyWidth - left_
        dom.style.height = '100%'
        dom.style.left = bodyWidth - xw - a + this.isScorllWidth + 'px'
        clickBg.style.left = bodyWidth - dw - a + +this.isScorllWidth + 'px'
      })
    },
    // 表格离开
    mouseLive() {
      this.chioceSetIndex = null
    },
    // 键盘移除
    mouseOut(row, idx) {
      this.isOutMouse = true
      if (row[idx] === this.rowIdxMouse) return
      this.chioceSetIndex = null
      this.isShowDomAnimate = false
      return this.chioceSetIndex = null
    },
    // 分组 每页打印表格数量
    groupSet(N, Q) {
      var R = []; var F
      for (F = 0; F < Q.length;) {
        R.push(Q.slice(F, F += N))
      }
      return R
    },
    // 处理td
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    },
    //  监听最大打印表格行数改变
    tableMaxNum(e) {
      console.log(e)
      if (e * 1 > this.pritInfo.tableList.length) {
        this.printmaxNumTable = this.pritInfo.tableList.length
      }
    }
  }
}

export default tableMixin
